import React from "react";
import styled from "@emotion/styled";
import "../index.css";
import { BankWrapper } from "../components/BankWrapper";
import { BankNavigation } from "../components/BankNavigation";
import {
  FlexColumn,
  FlexRow,
  SBNavOffsetContainer,
  StyledCard,
} from "../styled";
import {
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

const AdCard = styled(Card)`
  margin: 0;
  height: 8rem;
  display: flex;
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "center"};
  background: url("/assets/images/sycamore-leaves-background-opaque.jpg");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  > img {
    height: 6rem;
    margin-right: 2rem;
  }
`;

const now = Date.now();

const tableData = [
  {
    description: "ORIG CO NAME:SOUTHERN NUCLEAR CO ENTRY DESCR:DIR DEP SEC:PPD",
    amount: -124.27,
  },
  {
    description: "Amazon web services aws.amazon.co WA 04/01 (...3490)",
    amount: -52.97,
  },
  {
    description: "Online Payment 112840403482 To Farm Venue 02/21",
    amount: -1054.25,
  },
  {
    description: "NCOURT *AZPHOENIXSWP PHOENIX AZ 02/10 (...3490)",
    amount: -210.02,
  },
  {
    description: "CREDIT CRD AUTOPAY PPD ID: 57832094950",
    amount: -70.0,
  },
  {
    description: "TST* THE CLEVER KOI - C PHOENIX AZ 02/10 (...3490)",
    amount: -62.4,
  },
  {
    description: "GUSTO PAY 213759 PPD ID: 4832059867",
    amount: 4923.14,
  },
  {
    description: " SPRINT8006435411 ACHBILLPAY 2994593861 WEB ID: 2543566695 ",
    amount: -104.1,
  },
  {
    description: "AMZN Mktp US*0B92h7U Amzn.com/bill WA 01/09 (...3490)",
    amount: -28.38,
  },
  {
    description: "FRYS-FOOD-DRG #0 16322 PHOENIX AZ 754893 12/24 (...3490)",
    amount: -81.55,
  },
];

export default function Component() {
  return (
    <BankWrapper justify="start">
      <BankNavigation />
      <SBNavOffsetContainer>
        <Typography variant="h4" style={{ margin: "2rem 0" }}>
          Welcome back, John.
        </Typography>
        <FlexRow>
          <FlexColumn style={{ marginRight: "1rem" }}>
            <Select
              value="primary"
              label="Account"
              style={{ alignSelf: "start" }}
              MenuProps={{
                style: { display: "flex", flexDirection: "column" },
              }}
            >
              <MenuItem value="primary">Checking Account (****1245)</MenuItem>
              <MenuItem value="secondary">Savings Account (****3508)</MenuItem>
            </Select>
            <StyledCard style={{ width: "100%", marginTop: "1rem" }}>
              <Typography variant="caption" style={{ fontWeight: 500 }}>
                ACCOUNT BALANCE
              </Typography>
              <Typography variant="h1" color="primary">
                $18,721.54
              </Typography>
              <div style={{ display: "flex" }}>
                <div>
                  <Typography variant="caption" style={{ fontWeight: 500 }}>
                    MONTHLY DEPOSITS
                  </Typography>
                  <Typography variant="h3" color="primary">
                    $5,128.24
                  </Typography>
                </div>
                <div style={{ marginLeft: "2rem" }}>
                  <Typography variant="caption" style={{ fontWeight: 500 }}>
                    MONTHLY WITHDRAWALS
                  </Typography>
                  <Typography variant="h3" style={{ color: "firebrick" }}>
                    $2,250.03
                  </Typography>
                </div>
              </div>
              <Typography
                variant="h5"
                style={{ fontWeight: 700, color: "#606060", marginTop: "2rem" }}
              >
                QUICK LINKS
              </Typography>
              <List component="nav" aria-label="secondary mailbox folders">
                <ListItem button>
                  <ListItemText primary="View account & routing number" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Edit personal information" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Transfer money" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="See budget and spending plans" />
                </ListItem>
              </List>
              <AdCard>
                <img
                  src="/assets/images/sycamore-bank-wordmark.png"
                  alt="bank logo"
                />
                <Typography variant="h5">
                  Apply for Sycamore credit today!
                </Typography>
              </AdCard>
            </StyledCard>
          </FlexColumn>
          <FlexColumn style={{}}>
            <Button
              style={{ alignSelf: "flex-end" }}
              color="primary"
              size="small"
            >
              View Statement
            </Button>
            <StyledCard
              style={{ width: "100%", marginTop: "1rem", padding: 0 }}
            >
              <TableContainer>
                <Table size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((data, index) => (
                      <TableRow key={data.description}>
                        <TableCell>{data.description}</TableCell>
                        <TableCell>
                          {new Date(
                            now - index * 1000 * 60 * 60 * 18.3
                          ).toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: data.amount < 0 ? "firebrick" : "green",
                          }}
                        >
                          {data.amount.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={10}
                rowsPerPage={10}
                page={0}
              />
            </StyledCard>
          </FlexColumn>
        </FlexRow>
      </SBNavOffsetContainer>
    </BankWrapper>
  );
}
